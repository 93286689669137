"use client";

import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { GrLinkedinOption } from "@react-icons/all-files/gr/GrLinkedinOption";
import Link from "next/link";
import { StyledFooter } from "./footer.styles";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { CSSProperties } from "styled-components";
import { IPlatform, IPlugin } from "../../types";
import cn from "classnames";
import Image from "next/image";
import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { toast } from "react-toastify";
import { AccordionColumn } from "./accordion-column";

import apps from "../../../apps.json";
import platforms from "../../../platforms.json";
import {
  footerCompanyLinks,
  footerResourcesLinks,
  footerCommunityLinks,
  toolsLinks,
} from "@/helpers/cn-links";
import { usePathname } from "next/navigation";
import { BigBanner } from "../bigBanner/bigBanner.comp";

export const Footer = ({
  isDark = true,
  style,
  homepageLayout = false,
  transparent = false,
}: {
  isDark?: boolean;
  style?: CSSProperties;
  homepageLayout?: boolean;
  transparent?: boolean;
}) => {
  const pathname = usePathname();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState<string>("");

  const bigBanner = bigBannerData[pathname];

  function getSubscriptionInput() {
    const url =
      "https://commoninja.us11.list-manage.com/subscribe/post?u=4076742add55bb43380b94b22&amp;id=1cc0fef740";

    function notify(status: string) {
      setEmail("");
      if (!email) {
        toast.info("Email is required.");
        return;
      }
      if (status === "success") {
        toast.success("Great Success 🎉");
        setEmail("");
        return;
      }
      setEmail("");
      toast.error(`Oops, something went wrong, please try again.`);
    }

    return (
      <div className="subscription-container">
        <p>Sign-up and get updates</p>
        <MailchimpSubscribe
          url={url}
          render={({ status, subscribe, message }) => (
            <form
              className="input-wrapper"
              onSubmit={(e) => {
                e.preventDefault();
                subscribe({ EMAIL: email });
                notify(status as string);
              }}
            >
              <div className="input-container">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  type="email"
                />
                <button role="button" aria-label="Subscribe for updates">
                  <IoIosArrowForward size={16} />
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }

  if (pathname.includes("/pages/")) {
    return null;
  }

  return (
    <StyledFooter
      style={style}
      className={cn({
        dark: isDark,
        "homepage-layout": homepageLayout,
        transparent,
        "big-banner": bigBanner,
      })}
    >
      {bigBanner && <BigBanner {...bigBanner} />}
      <div className="content-wrapper">
        <div className="top">
          <div className="column">
            <Image
              src={"/assets/cn-logo-dark.svg"}
              alt="Common ninja logo"
              width={200}
              height={30}
            />
            {getSubscriptionInput()}
            <h2>Follow Us</h2>

            <div className="social-links">
              <Link
                rel="noopener noreferrer"
                href={"https://www.linkedin.com/company/common-ninja"}
                target="_blank"
                aria-label="Follow us on LinkedIn"
              >
                <GrLinkedinOption className="icon" />
              </Link>
              <Link
                rel="noopener noreferrer"
                href={"https://www.youtube.com/@commonninja"}
                target="_blank"
                area-label="Follow us on Youtube"
              >
                <FaYoutube className="icon" />
              </Link>
              <Link
                rel="noopener noreferrer"
                href={"https://www.instagram.com/commonninja_official/"}
                target="_blank"
                area-label="Follow us on Instagram"
              >
                <FaInstagram className="icon" />
              </Link>
              <Link
                rel="noopener noreferrer"
                href={"https://www.facebook.com/CommonNinja/"}
                target="_blank"
                area-label="Follow us on Facebook"
              >
                <FaFacebookF className="icon" />
              </Link>
              <Link
                rel="noopener noreferrer"
                href={"https://www.tiktok.com/@commonninja_official"}
                target="_blank"
                area-label="Follow us on Tiktok"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2859 3333"
                  fill="none"
                  style={{ fill: "currentColor", width: 15, height: 15 }}
                  className="icon"
                >
                  <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                </svg>
              </Link>
              <Link
                rel="noopener noreferrer"
                href={"https://twitter.com/commonninja"}
                target="_blank"
                area-label="Follow us on Twitter / X"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="642"
                  height="642"
                  viewBox="0 0 642 642"
                  fill="none"
                  data-src="https://website-assets.commoninja.com/distribution/1718898189380_Frame_6.svg"
                  //   xmlns:xlink="http://www.w3.org/1999/xlink"
                  role="img"
                >
                  <g clipPath="url(#clip0_2828_10-1)">
                    <path
                      d="M505.575 30.0938H604.042L388.972 276.542L642 611.906H443.903L288.74 408.473L111.186 611.906H12.6795L242.716 348.285L0 30.0938H203.153L343.39 215.993L505.575 30.0938ZM471.067 552.842H525.638L173.46 86.0681H114.958L471.067 552.842Z"
                      fill="white"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_2828_10-1">
                      <rect width="642" height="642" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </Link>
              {/* <Link
							href={'https://discord.com/invite/cxqUTbvMNd'}
							target="_blank"
						>
							<SiDiscord className="icon" />
							Discord
						</Link> */}
              {/* <Link href={'/'}>Homepage</Link> */}
              {/* <Link href={'/widgets'}>Our Widgets</Link> */}
              {/* <Link href={'/platforms'}>Supported Platforms</Link> */}
            </div>
          </div>

          <AccordionColumn
            label="Company"
            links={
              <>
                {footerCompanyLinks.map((link, idx) => (
                  <Link
                    key={link.name + link.url + idx}
                    href={link.url}
                    target={link.target}
                    rel={link.rel}
                  >
                    {link.name}
                  </Link>
                ))}
              </>
            }
          />

          <AccordionColumn
            label="Widgets+"
            // labelUrl="/widgets"
            links={
              <>
                {(apps as IPlugin[]).slice(0, 8).map((app) => (
                  <Link key={app.slug} href={`/widgets/${app.slug}`}>
                    {app.name}
                  </Link>
                ))}
                <Link className="bold-link" href={"/widgets"}>
                  See All Widgets
                  <IoIosArrowForward className="icon" />
                </Link>
              </>
            }
          />
          <AccordionColumn
            label="Platforms"
            // labelUrl="/platforms"
            links={
              <>
                {(platforms as IPlatform[]).slice(0, 8).map((platform) => (
                  <Link
                    key={platform.slug}
                    href={`/platforms/${platform.slug}`}
                  >
                    {platform.title}
                  </Link>
                ))}
                <Link className="bold-link" href={"/platforms"}>
                  See All Platforms
                  <IoIosArrowForward className="icon" />
                </Link>
              </>
            }
          />

          <AccordionColumn
            label="Resources"
            labelUrl="/resources"
            links={
              <>
                {footerResourcesLinks.map((link, idx) => (
                  <Link
                    key={link.name + link.url + idx}
                    href={link.url}
                    target={link.target}
                    rel={link.rel}
                  >
                    {link.name}
                  </Link>
                ))}
              </>
            }
          />

          <AccordionColumn
            label="Tools"
            // labelUrl="https://community.commoninja.com/"
            links={
              <>
                {toolsLinks.map((link, idx) => (
                  <Link
                    key={link.name + link.url + idx}
                    href={link.url}
                    target={link.target}
                    rel={link.rel}
                  >
                    {link.name}
                  </Link>
                ))}
                <h2>Community</h2>
                {footerCommunityLinks.map((link, idx) => (
                  <Link
                    key={link.name + link.url + idx}
                    href={link.url}
                    target={link.target}
                    rel={link.rel}
                  >
                    {link.name}
                  </Link>
                ))}
              </>
            }
          />
        </div>
        <div className="bottom">
          <small>
            © Copyright 2012-{currentYear}, Common Ninja. All rights reserved.
          </small>
          <div className="bottom-links">
            <Link href={"/terms"}>Terms & Conditions</Link>
            <Link href={"/privacy"}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const bigBannerData: { [key: string]: any } = {
  "/affiliates": {
    title: "Ready to Partner?",
    subtitle:
      "BY NOW WE MUST HAVE ENTICED YOU WITH OUR SIMPLE-TO-SET-UP, EASY-TO-EARN AFFILIATE PROGRAM, AND YOU ARE LOOKING FOR THE APPLY BUTTON. WELL, HERE IT IS!",
    buttonText: "Apply Now",
    buttonLink: "/affiliates/join",
  },
  "/pricing": {
    title: "We do offer plans for enterprises.",
    subtitle:
      "IF NONE OF THE PREMIUM PLANS WE OFFER IS ENOUGH FOR YOU - WORRY NOT!",
    buttonText: "Contact us",
    buttonLink: "https://commoninja.site/custom-plan",
  },
  "/ai-website-analyzer": {
    title: "Make Your Website 10X Better",
    subtitle: "More than 200 Widgets+ Are Waiting For You! Get Started Now.",
    buttonText: "Start Now",
    buttonLink: "/dashboard",
  },
};

[
  "/solutions",
  "/features/analytics",
  "/features/design",
  "/features/api",
  "/features/crm",
  "/features/integrations",
  "/features/localization",
  "/features/no-code-editor",
  "/features/project-management",
  "/features/payments",
].map((p) => {
  bigBannerData[p] = {
    title: "Start Your Journey",
    subtitle: "Ready to take your website to the next level?",
    buttonText: "Get Started",
    buttonLink: "/dashboard",
  };
});
